<template>
  <div>
    <van-nav-bar title="学生作业列表" left-text="返回" right-text="" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
    <section class="van-doc-demo-section">
      <div style="padding:20px 20px 5px 20px">
        <van-row>
          <van-col span="12">学习项目：{{listData.LearningProjectName}}</van-col>
          <van-col span="12" style="text-align: right;">评价表：{{listData.EvaluateName}}</van-col>
        </van-row>
        <van-row>
          <van-col span="8">评价数量：{{listData.People}}</van-col>
        </van-row>
      </div>

      <div class="van-coupon" v-for="(item,index) in listData.StudentJobTaskLogList" :key="index">
        <div class="van-coupon__content">
          <van-row>
            <van-col span="17" @click="jump(item)">
              <h2 class="van-coupon__amount">{{item.StudentName}}</h2>
              <p class="van-coupon__condition">提交时间：{{item.AddTime}}</p>
            </van-col>
            <van-col span="7" v-if="item.Score != null">
              <p class="messg_success">{{item.Score}}</p>
              <button class="reset" @click="reset(item)">重新评价</button>
            </van-col>
            <van-col span="7" v-else>
              <p class="messg_warning">未评价</p>
            </van-col>
          </van-row>
        </div>
      </div>

      <!--<div class="van-coupon">
                <div class="van-coupon__content">
                    <van-row>
                        <van-col span="18">
                            <h2 class="van-coupon__amount">张三三  13475678493</h2>
                            <p class="van-coupon__condition">提交时间：2017/03/10 12:00</p>
                        </van-col>
                        <van-col span="6">
                            <p class="messg_warning">未评价</p>
                        </van-col>
                    </van-row>
                </div>
            </div>

            <div class="van-coupon">
                <div class="van-coupon__content">
                    <van-row>
                        <van-col span="18">
                            <h2 class="van-coupon__amount">张三三  13475678493</h2>
                            <p class="van-coupon__condition">提交时间：2017/03/10 12:00</p>
                        </van-col>
                        <van-col span="6">
                            <p class="messg_success">68</p>
                        </van-col>
                    </van-row>
                </div>
            </div>-->
    </section>

  </div>

</template>

<script>
import Cookies from 'js-cookie';
export default {
  data() {
    return {
      jobTaskID: "",//作业ID
      teacherID: "", // 学生ID
      listData: [],
      contentshow: false
    }
  },
  methods: {
    // 获取数据
    initData() {
      this.$axios.get('/api/JobTask/TeacherJobTaskInfo?teacherID=' + this.teacherID + "&jobTaskID=" + this.jobTaskID, "").then(res => {
        var data = JSON.parse(res.data);
        console.log(data)
        if (data.code == 200) {
          this.listData = data.data;
          this.cookies.kkSet("learningProjectName", this.listData.LearningProjectName);
        }
      });
    },
    // 点击左上角返回
    onClickLeft() {
      this.$router.push({ name: "teacherwork" });
    },
    onClickRight() { },//这个方法不要删，上面导航返回控件必须带的
    jump(item) {
      if (item.Score != null) {
        this.cookies.kkSet("stuName", item.StudentName);
        this.$router.push({ name: "correctworkLog", query: { jobTaskLogID: item.ID } });
      }
      else {
        this.cookies.kkSet("stuName", item.StudentName);
        this.$router.push({ name: "correctwork", query: { jobTaskLogID: item.ID } });
      }
    },
    reset(item) {
      this.cookies.kkSet("stuName", item.StudentName);
      this.$router.push({ name: "teacherworkchange", query: { jobTaskLogID: item.ID } });
    }
  },
  mounted() {
    this.jobTaskID = this.cookies.kkGet("jobTaskID");
    this.teacherID = Cookies.get('teacherID');
    this.initData();
  },
}
</script>

<style>
.van-doc-demo-section {
  box-sizing: border-box;
  min-height: calc(100vh - 56px);
  padding-bottom: 20px;
}

.van-coupon {
  margin: 12px 12px 6px 12px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px rgb(222, 219, 219);
}

.van-coupon__content {
  padding: 10px 0 14px 15px;
}

.messg_warning {
  color: rgba(237, 122, 112, 1);
  text-align: center;
}

.messg_success {
  color: rgba(42, 204, 155, 1);
  font-size: 20px;
  text-align: center;
}

.van-coupon__amount {
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.van-coupon__description {
  font-size: 16px;
  text-align: center;
  border-top: 1px dashed #ebedf0;
}

.van-coupon__condition {
  font-size: 12px;
  line-height: 18px;
  white-space: pre-wrap;
}

.van-row {
  padding: 5px 0 5px 0;
  width: 100%;
}

.van-col--6 {
  line-height: 3em;
  font-weight: 100;
  padding-right: 15px;
}
.reset {
  position: relative;
  text-align: center;
  width: 75px;
  background-color: white;
  border-width: 0.5px;
  border-color: red;
  border-style: double;
  color: red;
  z-index: 9999;
}
</style>